import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/da';
import axios from 'axios';

dayjs.locale('da');

// 1. Define route components.
// These can be imported from other files
var apiUrl = 'https://www.addanmark.dk/wp-json/';
var apikey = '5d41402abc4b2a76b9719d911017c592';

var AllNewsTemplate = `
  <div class ="all-news">

    <loading-spinner :pageLoaded="pageLoaded"></loading-spinner>

    <div class="posts row">
      <ul>
        <li v-for="news in AllNews">

            <div class="col-md-9">
              <router-link :to="{ name: 'ad-newsItem', params: { newsid: news.slug}}"><h3 v-html="news.title.rendered"></h3></router-link>
              <small>- {{news.date}}</small>
              <p v-if="news.excerpt.rendered" v-html="news.excerpt.rendered"></p>
              <router-link :to="{ name: 'ad-newsItem', params: { newsid: news.slug}}" title="Læs mere..." class ="btn btn-default btn-xs"><i class="fal fa-2x fa-ellipsis-h"></i></router-link>
            </div>
        </li>
      </ul>
    </div>
    <div class ="pagination row" v-if="pagination">
      <ul>
        <li v-for="page in NumbersOfPages">
        <div>
          <button :class="{disabled : page == myCurrentPage}" class ="btn btn-default" @click="updatePosts(page)">Side {{page}}</button>
        </div>
        </li>
      </ul>
    </div>

  </div>
`;

// Show all News
var AllNews = Vue.component('AllNews', {
  data: function() {
    return {
      AllNews: '',
      publishDate: '',
      pagination: false,
      NumbersOfPages: [],
      myCurrentPage: '',
      paginationCreated: false,
      pageLoaded: false,
    };
  },

  // Get all posts
  created: function() {
    this.fetchPosts(1);
  },

  methods: {
    /**
     * Get all posts
     */
    fetchPosts: function(page) {
      if (this.$root.currentPage > 0) {
        page = this.$root.currentPage;
      }

      var self = this;
      axios
        .get(apiUrl + 'wp/v2/news?categories=5&per_page=10&page=' + page, {
          headers: {
            apikey: apikey,
          },
        })
        .then(function(response) {
          self.pageLoaded = true;
          self.setPosts(response, page);
        })
        .catch(function(error) {
          console.log('Error', error);
        });
    },

    /**
     * Set posts
     */
    setPosts: function(posts, page) {
      this.AllNews = posts.data;
      this.createPagination(posts.headers['x-wp-totalpages'], posts.headers['x-wp-total']);
      this.formatDate();
      //app.setCurrentPage(page, 'setPosts'); //TODO: how?
      this.myCurrentPage = page;
    },

    /**
     * format date
     */
    formatDate: function() {
      for (var i = 0; i < this.AllNews.length; i++) {
        this.AllNews[i].date = dayjs(this.AllNews[i].date).format('dddd D MMMM YYYY - HH:mm');
      }
    },

    /**
     * Create pagination
     */
    createPagination: function(totalPages, totalPosts) {
      if (!this.paginationCreated) {
        if (totalPages > 1) {
          this.pagination = true;
          for (var i = 1; i <= totalPages; i++) {
            this.NumbersOfPages.push(i);
          }
          this.paginationCreated = true;
        }
      }
    },

    /**
     * Update posts
     */
    updatePosts: function(pageId) {
      this.$root.currentPage = pageId;
      this.fetchPosts(pageId);
    },
  },
  template: AllNewsTemplate,
});

Vue.component('loading-spinner', {
  props: ['pageLoaded'],

  data: function() {
    return {};
  },
  template:
    "<div class='spinner' v-if='!pageLoaded'><div class='sk-fading-circle'><div class='sk-circle1 sk-circle'></div><div class='sk-circle2 sk-circle'></div><div class='sk-circle3 sk-circle'></div><div class='sk-circle4 sk-circle'></div><div class='sk-circle5 sk-circle'></div><div class='sk-circle6 sk-circle'></div><div class='sk-circle7 sk-circle'></div><div class='sk-circle8 sk-circle'></div><div class='sk-circle9 sk-circle'></div><div class='sk-circle10 sk-circle'></div><div class='sk-circle11 sk-circle'></div><div class='sk-circle12 sk-circle'></div></div></div>",
});

export default AllNews;
